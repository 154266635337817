import React, { createContext, useState } from 'react';

import { ChildrenType } from 'Type/Common.type';

export const CheckoutContext = createContext({
    resetShippingMethod: false,
    setResetShippingMethod: () => {}
});

CheckoutContext.displayName = 'CheckoutContext';

/** @namespace Scandipwa/Route/Checkout/Context/CheckoutContext/CheckoutProvider */
export const CheckoutProvider = ({ children }) => {
    const [resetShippingMethod, setResetShippingMethod] = useState(false);

    return (
        <CheckoutContext.Provider value={ { resetShippingMethod, setResetShippingMethod } }>
            { children }
        </CheckoutContext.Provider>
    );
};

CheckoutProvider.displayName = 'CheckoutProvider';

CheckoutProvider.propTypes = {
    children: ChildrenType.isRequired
};
