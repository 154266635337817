/**
 * Amasty Shipping Suite compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

const estimateShipping = (args, callback, instance) => {
    const {
        onShippingEstimationFieldsChange,
        customer: { addresses = [] }
    } = instance.props;

    const address = addresses.find(({ id }) => id === args[0]);

    if (!address) {
        return;
    }

    const {
        city,
        country_id,
        postcode,
        region: {
            region_id,
            region
        } = {},
        street = []
    } = address;

    if (!country_id) {
        return;
    }

    // send address to estimateShippingCosts mutation
    onShippingEstimationFieldsChange({
        city,
        country_id,
        region_id,
        region,
        postcode,
        street
    }, true);
};

export default {
    'Component/CheckoutAddressBook/Container': {
        'member-function': {
            estimateShipping
        }
    }
};
